<template>
    <vs-row vs-justify="center">                
        <vs-col vs-lg="6" vs-xs="12" vs-sm="12">
            <vs-row>
                <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
                    <vs-card>
                        <cEstadios :value="stats.countries"></cEstadios>
                    </vs-card>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
                    <vs-card>
                        <cPaises :value="stats.stadiums"></cPaises>
                    </vs-card>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
                    <vs-card>
                        <vue-apex-charts type="pie"
                                         :height="600"
                                         :options="chartOptions"
                                         :series="series"></vue-apex-charts>
                    </vs-card>
                </vs-col>
            </vs-row>
        </vs-col>
        <vs-col vs-lg="6" vs-xs="12" vs-sm="12">
            <vs-card>
                <h3 class="card-title">{{$t('SurveysByCountry')}}</h3>
                <div style="height:200px;">
                    <App :countryData="countryData"
                         defaultCountryFillColor="#93d5ed"
                         highColor="blue"
                         lowColor="yellow"
                         countryStrokeColor="white" />
                </div>
                <CountryDetalhes :info="ArrayCountries" :totStadiums="stats.stadiums" :key="stats"></CountryDetalhes>
            </vs-card>
        </vs-col>
    </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////
import cEstadios from "../../widgets/chart-widgets/Arena/WalletBalance";
import cPaises from "../../widgets/chart-widgets/Arena/ReferralEarnings";
import CountryDetalhes from "../../dashboards/dashboard-components/country-detalhes/CountryVisit";
import VueApexCharts from "vue-apexcharts";
    

import App from "vue-world-map";

import { Stats } from "../../../services/Arena.js";
const st = new Stats;

// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: "Principal",
        data: () => ({            
            title: "Home Arena2",
            resp: String,
            stats: {},            
            series: [],                          
            chartOptions: {
               labels: ['','','','','','','','','','','','','','','',''],
               //colors: ['#4fc3f7', '#2962FF', '#f62d51', '#ffbc34', '#212529'],
               responsive: [{
                    //breakpoint: 480,
                    options: {
                         chart: {
                      //        width: 400
                         },
                         legend: {
                              position: 'bottom'
                         }
                    }
               }]
          },
        }),        
        /*beforeRouteEnter(to , from, next) {                
            st.Get().then((resp) => {                                              
                next(vm => {
                    vm.stats = resp;                    
                    vm.setGraph();
                });
            }                                  
                , (err) => alert(err));                           
        },*/
        methods: {            
            setGraph() {                                                             
                this.series = [];                              
                for (var i = 0; i < this.stats.statsCountries.length; i++) {                    
                    var s = this.stats.statsCountries[i];                    
                    this.series.push(s.qtStadiums);      
                    this.chartOptions.labels[i]=s.country_name_original;                    
                }
                
            }
        },
        beforeMount() {         
            
        },
        created() {
           // dados do grafico
            this.$vs.loading();
            st.Get().then((resp) => {                
                    this.stats = resp;
                this.setGraph();
                this.$vs.loading.close();
                
            }
                , (err) => alert(err));                           
        },
        computed:
        {
            countryData() {                                          
                var obj = {};
                if (this.stats.countries != undefined) {
                    if (this.stats.statsCountries != undefined) {
                        var i;
                        var sc;
                        sc = this.stats.statsCountries;
                        for (i = 0; i < sc.length; i++) {
                            var s = this.stats.statsCountries[i]                            
                            obj[s.country_code] = s.qtStadiums;
                        }
                    }
                }                                                                           
                return obj;                
            },
            ArrayCountries() {
                var arr = [];
                if (this.stats.countries != undefined) {
                    arr = this.stats.statsCountries;                    
                }
                return arr;
            }        
        },       
  components: {
    cEstadios,
    cPaises,    
    CountryDetalhes,    
      App,
    VueApexCharts
  }
};
</script>