<template>
  <div>
    <CountryMap></CountryMap>      
    <div v-for="val in info" :key="val.country_id">        
        <vs-row class="mb-3">
            <vs-col vs-lg="3" vs-xs="3" vs-sm="3">
                <span>{{val.country_name_original}}</span>
            </vs-col>
            <vs-col vs-lg="7" vs-xs="7" vs-sm="7">
                <vs-progress :percent=PercentS(val.qtStadiums) color="primary"></vs-progress>                
            </vs-col>
            <vs-col vs-lg="2" vs-xs="2" vs-sm="2">
                <span>{{val.qtStadiums}}</span>
            </vs-col>
        </vs-row>
    </div>    
  </div>
</template>
<script>
import CountryMap from "./CountryMap";

export default {
  name: "CountryVisits",
  data: () => ({
    title: "CountryVisits"
        }),
   props: {
       info: [],
       totStadiums: Number
        },
        computed: {
            /*PercentS(val) {
                if (this.totStadiums != undefined)
                    return ((val / this.totStadiums) * 100);
                else
                    return (0);
            }*/                               
            
        },
        methods: {
             PercentS: function (val) {
                    if (this.totStadiums != undefined)
                    return ((val / this.totStadiums) * 100);
                else
                    return (0);
                }
        },
  components: {
    CountryMap
  }
};
</script>